import React, { useState, useRef, useEffect } from 'react';
import {
    Stage,
    SubscribeType,
    StageEvents,
    ConnectionState,
    StreamType,
    LocalStageStream,
} from 'amazon-ivs-web-broadcast';
import { IVSRealTimeClient, CreateParticipantTokenCommand } from "@aws-sdk/client-ivs-realtime";
import { connect } from 'react-redux';
import axios from 'axios';
import { Constants } from '../../Constants';
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';

const changeShowLiveAws = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}auctions/change-show-live-aws`, {
            auction_id: data.auction_id,
            show_live_aws: Number(data.show_live_aws)
        }, httpOptions);
    } catch (error) {
        throw error;
    }
}

const BroadcastComponent = ({ configAccount, sessionProps, show_live_aws, auction_id }) => {
    const [connected, setConnected] = useState(false);
    const [joining, setJoining] = useState(false);
    const [streamStarted, setStreamStarted] = useState(false);

    const [devices, setDevices] = useState({ video: [], audio: [] });
    const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
    const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
    const [visibility, setVisibility] = useState(show_live_aws);

    const videoRef = useRef(null);
    const localVideoRef = useRef(null);

    const [stage, setStage] = useState(null);

    const [connectedParticipants, setConnectedParticipants] = useState(0);
    const [connectedParticipantsInterval, setConnectedParticipantsInterval] = useState(null);

    useEffect(() => {
        async function fetchDevices() {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = mediaDevices.filter((device) => device.kind === "videoinput");
            const audioDevices = mediaDevices.filter((device) => device.kind === "audioinput");

            setDevices({ video: videoDevices, audio: audioDevices });

            if (videoDevices.length > 0) setSelectedVideoDevice(videoDevices[0].deviceId);
            if (audioDevices.length > 0) setSelectedAudioDevice(audioDevices[0].deviceId);
        }

        fetchDevices();
    }, []);

    useEffect(() => {
        setVisibility(show_live_aws)
    }, [show_live_aws]);

    useEffect(() => {
        if (stage && stage.token?.topic) {
            getViewerCount();
            const interval = setInterval(() => {
                getViewerCount();
            }, 60000);
            setConnectedParticipantsInterval(interval);
        }
        else {
            setConnectedParticipants(0);
            if (connectedParticipantsInterval) {
                clearInterval(connectedParticipantsInterval);
            }
        }
    }, [stage]);

    const getViewerCount = async () => {
        console.log('Obteniendo métricas de IVS...');

        const credentials = new AWS.Credentials({
            accessKeyId: configAccount?.config?.ak,
            secretAccessKey: configAccount?.config?.sk
        })

        const cloudwatch = new AWS.CloudWatch({
            region: 'us-east-1',
            credentials
        });

        const params = {
            MetricName: 'Subscribers',
            Namespace: 'AWS/IVSRealTime',
            Dimensions: [
                {
                    Name: 'Stage',
                    Value: stage.token?.topic,
                },
            ],
            EndTime: new Date(),
            StartTime: new Date(new Date().getTime() - 30 * 60 * 1000),
            Period: 60,
            Statistics: ['Maximum'],
        };

        try {
            const data = await cloudwatch.getMetricStatistics(params).promise();
            const viewerCount = data.Datapoints.length > 0 ? data.Datapoints[0].Maximum : 0;
            setConnectedParticipants(viewerCount);
        } catch (error) {
            console.error('Error al obtener las métricas de IVS:', error);
        }
    };

    const generateToken = async () => {
        if (!configAccount?.config?.arn || !configAccount?.config?.ak || !configAccount?.config?.sk) {
            alert('Atención, no se configuró correctamente la cuenta de transmisión');
            return;
        }

        const ivsRealtimeClient = new IVSRealTimeClient({
            region: 'us-east-1',
            credentials: {
                accessKeyId: configAccount?.config?.ak,
                secretAccessKey: configAccount?.config?.sk,
            },
        });

        const createStageTokenRequest = new CreateParticipantTokenCommand({
            stageArn: configAccount?.config?.arn,
            capabilities: ["PUBLISH", "SUBSCRIBE"],
        });
        const response = await ivsRealtimeClient.send(createStageTokenRequest);

        return response.participantToken?.token;
    };

    const joinStage = async () => {
        if (connected || joining || streamStarted) return;

        setJoining(true);

        const token = await generateToken();
        if (!token) {
            alert('No se pudo generar el token de conexión');
            setJoining(false);
            return;
        }

        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: selectedVideoDevice ? { deviceId: { exact: selectedVideoDevice } } : true,
                audio: selectedAudioDevice ? { deviceId: { exact: selectedAudioDevice } } : true,
            });

            if (!mediaStream) {
                alert('No se pudo obtener el stream de medios');
                setJoining(false);
                return;
            }

            if (localVideoRef.current) {
                localVideoRef.current.srcObject = mediaStream;
            }

            const audioTrack = new LocalStageStream(mediaStream.getAudioTracks()[0]);
            const videoTrack = new LocalStageStream(mediaStream.getVideoTracks()[0]);

            const strategy = {
                audioTrack: audioTrack,
                videoTrack: videoTrack,
                updateTracks(newAudioTrack, newVideoTrack) {
                    this.audioTrack = newAudioTrack;
                    this.videoTrack = newVideoTrack;
                },
                stageStreamsToPublish() {
                    return [this.audioTrack, this.videoTrack];
                },
                shouldPublishParticipant() {
                    return true;
                },
                shouldSubscribeToParticipant() {
                    return SubscribeType.AUDIO_VIDEO;
                }
            };

            const stageTmp = new Stage(token, strategy);

            stageTmp.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, (state) => {
                const isConnected = state === ConnectionState.CONNECTED;
                setConnected(isConnected);
                setJoining(false);
                setStreamStarted(isConnected);
            });

            stageTmp.on(StageEvents.STAGE_STREAM_PUBLISH_STARTED, () => {
                console.log('Stream iniciado correctamente.');
            });

            stageTmp.on(StageEvents.STAGE_STREAM_PUBLISH_ERROR, (error) => {
                console.log('Error al publicar el stream:', error);
            });

            stageTmp.on(StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED, (participant, streams) => {
                const videoStreams = streams.filter((stream) => stream.streamType === StreamType.VIDEO);
                const audioStreams = streams.filter((stream) => stream.streamType === StreamType.AUDIO);

                const mediaStreamTracks = [
                    ...videoStreams.map((stream) => stream.mediaStreamTrack),
                    ...audioStreams.map((stream) => stream.mediaStreamTrack),
                ];

                if (videoRef.current) {
                    videoRef.current.srcObject = new MediaStream(mediaStreamTracks);
                }
            });

            await stageTmp.join();
            setStage(stageTmp);
            console.log('Unido al Stage correctamente.');
        } catch (error) {
            console.error('Error al unirse al Stage:', error.message || error);
            setJoining(false);
            setConnected(false);
        }
    };

    const leaveStage = () => {
        if (stage) {
            stage.leave();
        }

        setJoining(false);
        setConnected(false);
        setStreamStarted(false);
        setStage(null);

        // reset video elements
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    };

    const changeVisibility = async (visibility) => {
        try {
            await changeShowLiveAws({
                auction_id,
                show_live_aws: visibility,
                token: sessionProps.account.token
            })
            setVisibility(visibility)
            toast.success('Visibilidad cambiada con éxito');
        } catch (error) {
            toast.error('Ocurrió un error al cambiar la visibilidad');
        }
    }

    return (
        <div>
            <div>
                <label>
                    Cámara:
                    <select
                        value={selectedVideoDevice || ""}
                        onChange={(e) => setSelectedVideoDevice(e.target.value)}
                    >
                        {devices.video.map((device) => (
                            <option key={device.deviceId} value={device.deviceId}>
                                {device.label || `Cámara ${device.deviceId}`}
                            </option>
                        ))}
                    </select>
                </label>
                <label style={{ marginLeft: '1rem' }}>
                    Micrófono:
                    <select
                        value={selectedAudioDevice || ""}
                        onChange={(e) => setSelectedAudioDevice(e.target.value)}
                    >
                        {devices.audio.map((device) => (
                            <option key={device.deviceId} value={device.deviceId}>
                                {device.label || `Micrófono ${device.deviceId}`}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <div style={{ margin: '1rem 0', display: 'flex', gap: '1rem' }}>
                <button onClick={joinStage} disabled={joining || connected || streamStarted}>
                    {joining ? 'Conectando...' : 'Iniciar Stream'}
                </button>
                <button onClick={leaveStage} disabled={!connected}>
                    Detener Stream
                </button>
                {/* SELECT PARA PREGUNTAR SI MOSTRARLO PULICO O NO */}
                <select
                    value={visibility}
                    onChange={(e) => changeVisibility(e.target.value)}
                >
                    <option value={1}>Público</option>
                    <option value={0}>Privado</option>
                </select>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                    Conectados: {connectedParticipants}
                </div>
            </div>
            <div style={{ display: 'flex', gap: '1rem' }}>
                <video
                    ref={localVideoRef}
                    autoPlay
                    muted
                    playsInline
                    style={{ width: '50%', border: '1px solid #ccc' }}
                />
                <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    controls
                    style={{ width: '50%' }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer,
});

export default connect(mapStateToProps, null)(BroadcastComponent);
